import './modules/orgs'

// ****************************************
// DATE FIELD PLACEHOLDER
// ****************************************
function datePlaceholder() {
	if ($(".js-row-date").length) {
		var dateInput = $(".js-row-date").find('input');
		
		dateInput.change(function() {
			$(this).addClass("active");
		});
	}
}

// ****************************************
// CATEGORIES TOGGLE
// ****************************************
function categoriesToggle() {
	if ($(".js-categories-draw").length) {
		$('.js-categories-draw').slideUp();

		$('.categories__toggle').click(function() {
			var categoryDraw = $(this).closest(".filter__categories").find(".js-categories-draw");

			$(this).addClass("categories__toggle--hidden")
		
			if (categoryDraw.hasClass("js-categories-draw--active")) {
				categoryDraw.slideUp().removeClass("js-categories-draw--active");
				$(".categories__toggle").removeClass("categories__toggle--hidden");
			} else {
				categoryDraw.slideDown().addClass("js-categories-draw--active");
			}
		});
	}
}

// ****************************************
// SEARCH TOGGLE
// ****************************************
function searchToggle() {
	if ($(".js-search-toggle").length) {
		$('.header__searchbar').slideUp().removeClass("header__searchbar--active");

		$('.js-search-toggle').click(function() {

			// On small screens, close the nav draw first
			if ($(window).width() <= 1000) {
				$("html").removeClass('nav-active-fixed');
				$(".js-nav-toggle").removeClass('nav__toggle--active');
				$('.nav__list').removeClass('nav__list--active');
				window.scrollTo(0,0)
			}

			if ($('.header__searchbar').hasClass("header__searchbar--active")) {
				$('.header__searchbar').slideUp().removeClass("header__searchbar--active");
			} else {
				$('.header__searchbar').slideDown().addClass("header__searchbar--active");
			}
		});
	}
}

// ****************************************
// MOBILE NAV TOGGLE
// ****************************************
function navToggle() {
	if ($(".js-nav-toggle").length) {
		$('.js-nav-toggle').click(function() {
			$("html").toggleClass('nav-active-fixed');
			$(this).toggleClass('nav__toggle--active');
			$('.nav__list').toggleClass('nav__list--active');
		});
	}
}

// ****************************************
// MOBILE NAV BACK BUTTON
// ****************************************
function navBackInit() {
	if ($(".js-nav-back").length) {
		$('.js-nav-back').click(function() {
			$('.nav__dropdown').removeClass('nav__dropdown--active');
			$(this).removeClass('nav__back--active');
		});
	}
}

function showNavBack(element) {
	if (element.hasClass("nav__dropdown--active")) {
		$(".js-nav-back").addClass("nav__back--active");
	}
}

// ****************************************
// DROPDOWN TOGGLE
// ****************************************
function dropdownToggle () {
	if ($(".js-dropdown-toggle").length) {
		$('.js-dropdown-toggle').click(function() {
			var sibling = $(this).siblings('.nav__dropdown');
			sibling.toggleClass('nav__dropdown--active');
			showNavBack(sibling);
		});
	}
}

// ****************************************
// VIDEO MODAL
// ****************************************
function videoModal () {
	if ($(".js-modal").length) {
		$('.js-modal').click(function() {
			
			var modalType = $(this).attr('data-modal-type');

			if (modalType == "vimeo" ) {
				var video = "https://player.vimeo.com/video/" + $(this).attr('data-modal-vid');
			} else {
				var video = "https://www.youtube.com/embed/" + $(this).attr('data-modal-vid');
			}

			console.log(video)
			
			var copy = $(this).attr('data-modal-copy');
			var link = $(this).attr('data-modal-link');
			var linkTitle = $(this).attr('data-modal-link-text');
			

			$('.modal').addClass('modal--active');

			if (video.length > 0) {
				$('.modal__video iframe').attr("src", video)
			}

			if (copy.length > 0) {
				$('.modal__copy').append(copy)
			}

			if (link.length > 0) {
				$('.modal__button').attr("href", link)
			}

			if (linkTitle.length > 0) {
				$('.modal__button').append(linkTitle)
			}
		});

		$('.js-modal-close').click(function() {
			$('.modal').removeClass('modal--active');
			setTimeout(function(){
				$('.modal__content').html('<div class="modal__video"><iframe src="" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><p class="modal__copy"></p><a class="modal__button button" href=""></a>');
			}, 250);
		});
	}

}


// ****************************************
// ACCORDION
// ****************************************
function accordion () {
	if ($(".js-accordion").length) {
		$('.accordion__content').slideUp();
		$('.accordion__title').click(function() {
			$(this).siblings('.accordion__content').slideToggle();
			$(this).toggleClass('accordion__title--active');
		});
	}
}

// ****************************************
// FAQ SEARCH
// ****************************************
function faqSearch() {
	const results = document.querySelectorAll('.js-result');
	Array.from(results, (result) => {
		result.addEventListener('click', (e) => {
			e.preventDefault();
			const content = document.getElementById(e.target.hash.substring(1));
			content.scrollIntoView();
			const button = content.querySelector('button');
			if (!button.classList.contains('accordion__title--active')) {
				button.click();
			}
		});
	});
}

function handlePasswordSave(e) {
	
	let password = $('input[name="newPassword"]', this);
	if (password.length === 0) {
		password = $('input[name="password"]', this);
	}
	const passwordConfirmation = $('input[name="confirm_password"]', this);
	const submit = $('button', this);
	const message = $('.js-error', this);
	
	if (password.val() !== passwordConfirmation.val()) {
		message.removeClass('js-error__hidden');
		e.preventDefault();
		return;
	}

	message.addClass('js-error__hidden');
	submit.attr('disabled', 'true');
	
}

categoriesToggle();
searchToggle();
navToggle();
dropdownToggle();
accordion();
videoModal();
navBackInit();
faqSearch();
datePlaceholder();



$('form[data-validate-password]').submit(handlePasswordSave);

document.onreadystatechange = function() {
	switch (document.readyState) {
		case 'loading':
			// The document is still loading.

			break;
		case 'interactive':
			// The document has finished loading. We can now access the DOM elements.
			// But sub-resources such as images, stylesheets and frames are still loading.
			// Equivalent of document.ready

			break;
		case 'complete':
			// The page is fully loaded.
			// Equivalent of window.load

			break;
	}
};

// Resize event
window.addEventListener('resize', function(event){
	// Example
	// if ($app.wW() >= $app.breakpoints().small) {}
});

// Utilities and behaviours namespaced to $app
// use these functions by calling $app.method();
var $app = {

	// function for setting cookies
	setCookie: function(name,value,days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	},

	// function for getting cookies
	getCookie: function(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	},

	// function to erase a cookie
	eraseCookie: function(name) {
		document.cookie = name+'=; Max-Age=-99999999;';
	}


} // end of $app
