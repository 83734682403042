// adapted from https://www.youtube.com/watch?v=1iysNUrI3lw&ab_channel=TraversyMedia
const search = document.getElementById('workOrganisation');
const regionField = document.getElementById('workAddressRegion');
const countryField = document.getElementById('workAddressCountry');
const matchList = document.getElementById('matchList');
const otherOrganisationWrapper = document.getElementById('workOrganisationOtherWrapper');
const otherOrganisationInput = document.getElementById('workOrganisationOther'); 
const otherCountryWrapper = document.getElementById('workAddressCountryOtherWrapper');
const otherCountryInput = document.getElementById('workAddressCountryOther');


let matches = [];

if (otherCountryInput) {
	
	otherCountryInput.addEventListener('change', function(e) {
		countryField.value = e.target.value;
	});
	
}

// get the data to search from the json file
const searchOrgs = async searchText => {
	// const res = await fetch('/js/datasources/organisations.json');
	// const orgs = await res.json();
	const res = await fetch('/actions/bsccp/organisation-list/verbose');
	const orgs = await res.json();

	// get matches for text input
	let matches = orgs.filter(org => {
		// adjust the regex accordingly,
		// i.e. to match the at the start of the string use RegExp(`^${searchText}`, 'gi');
		const regex = new RegExp(`${searchText}`, 'gi');
		// return a match if the search string is in the organistaion name OR region
		return org.organisation.match(regex) || org.region.match(regex)
	});

	// if there are no results then show nothing (default would show all)
	if(searchText.length === 0) {
		matches = [];
		matchList.innerHTML = '';
		matchList.classList.remove('show');
	}

	// create the list of results
	outputHtml(matches);
}

const regionMap = {
	'Yorkshire & The Humber': 'North East',
	'East Midlands': 'Midlands',
	'East of England': 'Eastern England',
	'South Central': 'South East',
	'South East Coast': 'South East',
	'West Midlands': 'Midlands',
};

/**
 * Map the region name from NHS data to a valid option id of the region field
 * @param region
 * @returns {*}
 */
const mapRegionValue = region => {
	const regionName = regionMap[region] ?? region;
	return window.regionFieldOptions.find(option => option.label === regionName)?.value;
}

// create the results list
const outputHtml = matches => {
	if(matches.length > 0) {
		// map the results array into this html string template, then join them all into a single string of html
		const html = matches.map(match => `
			<div id="org${match.id}" class="autocomplete__result js-autocomplete-result" data-country="${match.country}" data-region="${match.region}">${match.organisation}</div>
		`).join('');

		// bind a click event to each result in order to populate fields with its data
		matchList.addEventListener('click', function(e){
			search.value = e.target.textContent
			matches = [];
			matchList.innerHTML = '';
			matchList.classList.remove('show')

			regionField.value = mapRegionValue(e.target.dataset.region);
			countryField.value = e.target.dataset.country;
			
			// toggle to manual input
			if (['Other', 'Overseas'].includes(e.target.textContent)) {
				otherOrganisationWrapper.classList.remove('hidden');
				otherCountryWrapper.classList.remove('hidden');
				otherOrganisationInput.setAttribute('required', 'required');
				otherCountryInput.value = '';
				countryField.value = '';
			} else {
				otherOrganisationWrapper.classList.add('hidden');
				otherCountryWrapper.classList.add('hidden');
				otherOrganisationInput.removeAttribute('required');
				otherOrganisationInput.value = '';
			}
			
		})

		// inject the results into the page
		matchList.classList.add('show')
		matchList.innerHTML = html;
	}
}

if (search) {
	// event listeners for the search input
	search.addEventListener('input', () => searchOrgs(search.value));

	// clear region and country fields if nothing is entered in the work organistation field
	search.addEventListener('focusout', function(el){
		if(el.target.value.trim() === ''){
			regionField.value = '';
			countryField.value = '';
		}
	})
}
